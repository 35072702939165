import axios from 'axios'
import qs from "qs";

export default {
  getRequest (url, args) {
    return axios.get(url, {
      params: args,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('accessToken')
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
  },
  postRequest (url, ...args) {
    return axios.post(url, ...args, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('accessToken')
      }
    })
  },
  putRequest (url, ...args) {
    return axios.put(url, ...args, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('accessToken')
      }
    })
  },
  deleteRequest (url, args) {
    return axios.delete(url, {
      params: args,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem('accessToken')
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    })
  }
}
